import React from "react";
import { Laptop, Lightbulb, Users } from "lucide-react";
import dpLogo from "../assets/just-logo.png";
import { useNavigate } from "react-router-dom";

export default function HomePage() {
  return (
    <div className="flex-col bg-black items-center justify-center">
      <section className="w-full py-12 md:py-24 lg:py-32 xl:py-48">
        <div className="container px-4 md:px-6 mx-auto">
          <div className="flex flex-col items-center space-y-8 text-center">
            <div className="bg-white p-8 rounded-lg shadow-lg">
              <img
                src={dpLogo}
                alt="Digital Philosophy Logo"
                className="w-48 h-auto mx-auto"
              />
            </div>
            <div className="space-y-2">
              <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none text-white">
                Digital Philosophy
              </h1>
              <p className="mx-auto max-w-[400px] text-gray-300 md:text-xl">
                A small group of creative builders, who invest time, energy and
                capital into new ideas.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
