import React from "react";
import { Outlet, Link } from "react-router-dom";
import dpLogo from "../assets/just-logo.png";

const Layout = () => {
  return (
    <div className="flex-col min-h-screen">
      <header className="px-4 lg:px-6 h-14 flex items-center">
        <Link to="/" className="flex items-center justify-center">
          <img
            src={dpLogo}
            alt="Digital Philosophy Logo"
            className="h-8 w-auto mr-2"
          />
          <span className="ml-2 text-lg font-bold">Digital Philosophy</span>
        </Link>
        <nav className="ml-auto flex gap-4 sm:gap-6">
          <Link
            to="/"
            className="text-sm font-medium hover:underline underline-offset-4"
          >
            Home
          </Link>
          <Link
            to="/privacy"
            className="text-sm font-medium hover:underline underline-offset-4"
          >
            Privacy Policy
          </Link>
        </nav>
      </header>
      <main className="flex-grow">
        <Outlet />
      </main>
      <footer className="mt-auto py-6 w-full shrink-0 px-4 md:px-6 border-t">
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <p className="text-xs text-gray-500 mb-2 sm:mb-0">
            © 2023 Digital Philosophy PTE. LTD. All rights reserved. 90 Eu Tong
            Sen Street #03-02B, Singapore 059811
          </p>
          <nav>
            <Link
              to="/privacy"
              className="text-xs hover:underline underline-offset-4"
            >
              Privacy Policy
            </Link>
          </nav>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
