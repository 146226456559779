import React from "react";

export default function PrivacyPolicy() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      <p className="mb-4">Last updated: 28th of August, 2024</p>
      <p className="mb-4">
        Digital Philosophy PTE. LTD. ("us", "we", or "our") operates the Digital
        Philosophy website (the "Service").
      </p>
      <p className="mb-4">
        This page informs you of our policies regarding the collection, use, and
        disclosure of personal data when you use our Service and the choices you
        have associated with that data.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-4">
        Information Collection and Use
      </h2>
      <p className="mb-4">
        We collect several different types of information for various purposes
        to provide and improve our Service to you.
      </p>
      {/* Add more sections as needed */}
      <h2 className="text-2xl font-semibold mt-6 mb-4">Contact Us</h2>
      <p className="mb-4">
        If you have any questions about this Privacy Policy, please contact us:
      </p>
      <ul className="list-disc list-inside">
        <li>By email: privacy@digitalphilosophy.io</li>
        <li>By mail: 90 Eu Tong Sen Street #03-02B, Singapore 059811</li>
      </ul>
    </div>
  );
}
